<template>
  <a-drawer class="apply-edit" title="修改人员申请信息" @close="onClose" :width="720" :visible="applyEditVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="职位名称" prop="jobName" v-bind="formItemLayout" >
        <a-input v-model="form.jobName" />
      </a-form-model-item>
      <a-form-model-item label="申请人姓名" prop="username" v-bind="formItemLayout" >
        <a-input v-model="form.username" disabled />
      </a-form-model-item>
      <a-form-model-item label="简历地址" prop="resumeUrl" v-bind="formItemLayout" >
        <a-input v-model="form.resumeUrl" />
      </a-form-model-item>
      <a-form-model-item label="候选人来源" prop="source" v-bind="formItemLayout" >
        <a-select v-model="form.source">
          <a-select-option value="0">内部</a-select-option>
          <a-select-option value="1">外部</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: "ApplyEdit",
  props: {
    applyEditVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      form: {
        id: "",
        jobName: "",
        username: "",
        resumeUrl: "",
        source: ""
      },
      rules: {
        jobName: [
          { required: true, message: '职位名称不能为空', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '候选人姓名不能为空', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '候选人来源不能为空', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$put("user-recruit-apply", {...this.form}).then(r => {
            console.log("修改职位申请人员相关", r.data);
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    },
    setFormFields({...apply}) {
      let {id, jobName, userId, username, resumeUrl, source} = apply;
      this.form =  {id, userId, jobName, username, resumeUrl, source};
    }
  }
}
</script>