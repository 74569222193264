<template>
  <a-card class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="5">
            <a-form-item label="候选人来源">
              <a-select v-model="queryParams.source">
                <a-select-option value="0">内部</a-select-option>
                <a-select-option value="1">外部</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
				<a-row>
				  <a-col :span="12"></a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>

    <div class="operator">
      <!-- <a-button type="primary" ghost>新增</a-button> -->
      <!-- <a-button>删除</a-button> -->
    </div>
    <a-table
      ref="TableInfo"
      bordered
      :loading="loading"
      :columns="columns"
      :pagination="pagination"
      @change="handleTableChange"
      :data-source="dataSource"
    >
      <a-tag slot="source" slot-scope="text">{{
        text == "0" ? "内部" : text == "1" ? "外部" : "未知"
      }}</a-tag>
      <a-tag slot="resumeUrl" style="cursor: pointer;" :color="text?'#d63031':'#74b9ff'" slot-scope="text" @click="show(text)">{{
        text ? "查看" : "暂无"
      }}</a-tag>
      <!-- <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editRecruitApply(record)" title="修改"></a-icon>
      </template> -->
    </a-table>
    <ApplyEdit
      ref="applyEdit"
      @close="handleApplyEditClose"
      @success="handleApplyEditSuccess"
      :applyEditVisiable="applyEditVisiable"
    />

    <viewer ref="viewer" :images="resumeImages" @inited="inited" class="viewer">
      <img v-for="(src, index) in resumeImages" :src="src" :key="index" style="display: none;" />
    </viewer>
  </a-card>
</template>

<script>
import ApplyEdit from "./ApplyEdit";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

const columns = [
  // {
  //   title: "序号",
  //   dataIndex: "key",
  //   width: 100
  // },
  {
    title: "申请职位",
    dataIndex: "jobName",
    width: 500,
    // ellipsis: true
  },
  {
    title: "推荐人姓名",
    dataIndex: "username",
    width: 200,
    // ellipsis: true
  },
  {
    title: "申请类型",
    dataIndex: "source",
    scopedSlots: { customRender: "source" },
    width: 200,
    // ellipsis: true
  },
  {
    title: "候选人姓名",
    dataIndex: "candidate",
    width: 200,
    // ellipsis: true
  },
  {
    title: "候选人手机",
    dataIndex: "phone",
    width: 200,
    // ellipsis: true
  },
  {
    title: "候选人简历",
    scopedSlots: { customRender: "resumeUrl" },
    dataIndex: "resumeUrl",
    // ellipsis: true
  },
  // {
  //   title: "操作",
  //   dataIndex: "operation",
  //   scopedSlots: { customRender: 'operation' },
  //   fixed: "right",
  // }
];

export default {
  components: {
    ApplyEdit,
    Viewer,
  },
  data() {
    return {
      dataSource: [],
      resumeImages: [],
      queryParams: {
        source: "",
      },
      columns,
      loading: false,
      applyEditVisiable: false,
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("user-recruit-apply", { ...params }).then((r) => {
        console.log("分页获取申请人员信息", r.data);
        let rows = r.data.rows || [];
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        if (rows) {
          for (const key in rows) {
            rows[key]["key"] = +key + 1;
          }
        }
        this.dataSource = rows;
        this.pagination = pagination;
        this.loading = false;
      });
    },
    search() {
      this.fetch({
        ...this.queryParams,
      });
    },
    editRecruitApply(record) {
      this.$refs.applyEdit.setFormFields(record);
      this.applyEditVisiable = true;
    },
    handleApplyEditClose() {
      this.applyEditVisiable = false;
    },
    handleApplyEditSuccess() {
      this.applyEditVisiable = false;
      this.$message.success("修改职位申请人员相关成功！");
      this.fetch();
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    show(text) {
      if(!text) {
        this.$message.warning("未检测到简历！");
        return;
      }
      this.resumeImages = text?JSON.parse(text).map(item => `${this.$store.state.baseinfo.filedomain}${item.url}`):[];
      // console.log("=======>  ", this.resumeImages);
      // console.log("=======>  ", this.$viewer);
			this.$viewer.index = 0;
      this.$viewer.show();
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    source: "",
		  },
		  this.fetch();
		},
  },
};
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>